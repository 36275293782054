var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center w-100" },
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            width: "300",
            "multi-line": "",
            top: "",
            right: "",
            timeout: -1,
          },
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column w-100" },
            [
              _vm.operationType === _vm.operationTypes.MOVE_OPERATION
                ? _c(
                    "span",
                    { staticClass: "font-weight-bold text-uppercase ml-1" },
                    [_vm._v("file move")]
                  )
                : _vm.operationType === _vm.operationTypes.COPY_OPERATION
                ? _c(
                    "span",
                    { staticClass: "font-weight-bold text-uppercase ml-1" },
                    [_vm._v("file copy")]
                  )
                : _vm._e(),
              _c("v-divider", { attrs: { color: "white" } }),
              _c(
                "div",
                {
                  staticClass: "mt-1 d-flex align-center justify-space-between",
                },
                [
                  _c("span", [
                    _vm.operationType === _vm.operationTypes.MOVE_OPERATION
                      ? _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Items to move")]
                        )
                      : _vm.operationType === _vm.operationTypes.COPY_OPERATION
                      ? _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Items to copy")]
                        )
                      : _vm._e(),
                    _vm._v(
                      " : " + _vm._s(_vm.selectedFiles.length) + " selected "
                    ),
                  ]),
                ]
              ),
              _vm.filesToDisplay.length
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-start flex-column" },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.filesToDisplay, function (file) {
                          return _c("li", { key: file.fid }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("span", { staticClass: "mr-3" }, [
                                      _vm._v(_vm._s(file.short_id)),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0 pa-0",
                                        attrs: {
                                          disabled: _vm.copyingOrMovingFiles,
                                          small: "",
                                          icon: "",
                                          color: "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit(
                                              "removeFileFromMoveCopyList",
                                              { fid: file.fid }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("clear"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.alreadyExistingfiles.includes(file.fid)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "File already exists in the target directory"
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                      _vm.selectedFiles.length > 3 && !_vm.expandFileList
                        ? _c(
                            "a",
                            {
                              staticClass: "secondary--text caption ml-3",
                              on: {
                                click: function ($event) {
                                  _vm.expandFileList = true
                                },
                              },
                            },
                            [_vm._v("show all")]
                          )
                        : _vm.selectedFiles.length > 3 && _vm.expandFileList
                        ? _c(
                            "a",
                            {
                              staticClass: "secondary--text caption ml-3",
                              on: {
                                click: function ($event) {
                                  _vm.expandFileList = false
                                },
                              },
                            },
                            [_vm._v("show less")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "d-flex justify-start flex-column" }, [
                _c(
                  "span",
                  [
                    _vm.operationType === _vm.operationTypes.MOVE_OPERATION
                      ? _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Move to")]
                        )
                      : _vm.operationType === _vm.operationTypes.COPY_OPERATION
                      ? _c(
                          "span",
                          { staticClass: "font-weight-bold secondary--text" },
                          [_vm._v("Copy to")]
                        )
                      : _vm._e(),
                    _vm._v(" : "),
                    _c("v-icon", { attrs: { color: "white", small: "" } }, [
                      _vm._v("folder"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.snapshotStore.pathArray.join("/")
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "caption white--text font-italic" }, [
                  _c("sup", [_vm._v("*")]),
                  _vm._v(
                    " Navigate within the file browser to select the desired target location. "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-end mt-1" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ma-0",
                      attrs: {
                        disabled: _vm.copyingOrMovingFiles,
                        color: "white",
                        small: "",
                        text: "",
                      },
                      on: { click: _vm.clearFiles },
                    },
                    [_vm._v("cancel")]
                  ),
                  _vm.operationType === _vm.operationTypes.MOVE_OPERATION
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text ml-1",
                          attrs: {
                            disabled: !_vm.selectedFiles.length,
                            loading: _vm.copyingOrMovingFiles,
                            color: "white",
                            small: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.moveSelectedFiles()
                            },
                          },
                        },
                        [_vm._v(" move ")]
                      )
                    : _vm._e(),
                  _vm.operationType === _vm.operationTypes.COPY_OPERATION
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text ml-1",
                          attrs: {
                            disabled: !_vm.selectedFiles.length,
                            loading: _vm.copyingOrMovingFiles,
                            color: "white",
                            small: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copySelectedFiles()
                            },
                          },
                        },
                        [_vm._v(" copy ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }